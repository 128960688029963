// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Player_container__WWip6{\r\n    padding: 2vh 1vh 1vh;\r\n    min-height: 6vh;\r\n    width: 100%;\r\n}\r\n.Player_row__m3AdL{\r\n    min-height: 6vh;\r\n}\r\n.Player_sidebar__PIOP2{\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n}\r\n.Player_sidebar__PIOP2 > *{\r\n    position: relative;\r\n    left: 0.5vw;\r\n    margin-bottom: 0.5em;\r\n}\r\n.Player_placeholder__qDmTa{\r\n    position: absolute;\r\n    color: #aaa;\r\n    font-style: oblique;\r\n}\r\n.Player_front_options__pGA6- > *{\r\n    margin-bottom: 1em;\r\n    width: 100%;\r\n}\r\n.Player_back_options__aiiIY > *{\r\n    margin-bottom: 1em;\r\n    width: 100%;\r\n}", "",{"version":3,"sources":["webpack://./src/components/Main/InfoRight/EnhancerPanel/resource/Player.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,eAAe;IACf,WAAW;AACf;AACA;IACI,eAAe;AACnB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,oBAAoB;AACxB;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,WAAW;AACf;AACA;IACI,kBAAkB;IAClB,WAAW;AACf","sourcesContent":[".container{\r\n    padding: 2vh 1vh 1vh;\r\n    min-height: 6vh;\r\n    width: 100%;\r\n}\r\n.row{\r\n    min-height: 6vh;\r\n}\r\n.sidebar{\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n}\r\n.sidebar > *{\r\n    position: relative;\r\n    left: 0.5vw;\r\n    margin-bottom: 0.5em;\r\n}\r\n.placeholder{\r\n    position: absolute;\r\n    color: #aaa;\r\n    font-style: oblique;\r\n}\r\n.front_options > *{\r\n    margin-bottom: 1em;\r\n    width: 100%;\r\n}\r\n.back_options > *{\r\n    margin-bottom: 1em;\r\n    width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Player_container__WWip6",
	"row": "Player_row__m3AdL",
	"sidebar": "Player_sidebar__PIOP2",
	"placeholder": "Player_placeholder__qDmTa",
	"front_options": "Player_front_options__pGA6-",
	"back_options": "Player_back_options__aiiIY"
};
export default ___CSS_LOADER_EXPORT___;
